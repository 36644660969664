import React from 'react';
import './Home.css';
import Navbar from '../components/NavBar';
import NotificationManager from '../components/NotificationManager';

const Home: React.FC = () => {
    return (
        <div className="home">
            <Navbar />
            <NotificationManager />
            <title>Home - sr.wlvf.dev</title>
            <div style={{ textAlign: 'center', marginTop: '44vh' }}>
                <p>Hello!</p>
                <br />
                <p>This site is currently under construction 💙</p>
            </div>
        </div>
    );
};

export default Home;
