import { useState, useRef, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { NavBarProps } from '../types/common';
import './NavBar.css';

const homepage = process.env.REACT_APP_HOMEPAGE_URL || 'http://localhost:3000';

const Navbar: React.FC<NavBarProps> = (props: NavBarProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setDropdownOpen(false);
            }
        };

        // Add event listeners for clicks and escape key
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscape);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return (
        <>
            <nav className="navbar" style={props.transparent ? { backgroundColor: 'rgba(19, 21, 34, 0.42069)' } : {}}>
                <div className="navbar-brand">
                    <img src="/img/logo.png" alt="Logo" className="navbar-logo" />
                    <a className="navbar-brand-text" href={homepage}>
                        WLVF Song Requests
                    </a>
                </div>
                {user.loggedIn !== null && (
                    <div>
                        {user.loggedIn ? (
                            <div>
                                <div className="profile-dropdown" ref={dropdownRef}>
                                    <div onClick={toggleDropdown} className="profile-button">
                                        <span className="profile-username">{user.username}</span>
                                        <img src={user.avatarUrl} alt="User Profile" className="profile-pic" />
                                    </div>

                                    {isDropdownOpen && (
                                        <motion.div
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            transition={{ duration: 0.3 }}
                                            className="dropdown-menu"
                                        >
                                            <a href={`/user/${user.username}`} className="dropdown-item">
                                                Profile
                                            </a>
                                            <a href="/settings" className="dropdown-item">
                                                Settings
                                            </a>
                                            <div onClick={handleLogout} className="dropdown-item dropdown-item-logout">
                                                Logout
                                            </div>
                                        </motion.div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="login-button" onClick={() => navigate('/login')}>
                                Login
                            </div>
                        )}
                    </div>
                )}
            </nav>
        </>
    );
};

export default Navbar;
