import React, { FC, useState, useEffect, useRef } from 'react';
import './Modal.css'; // Optional: for basic modal styling
import { Close } from '@mui/icons-material';
import { ModalProps } from '../types/common';

const Modal: FC<ModalProps> = ({ isOpen, onClose, onSave, title, placeholder, initialValue = '' }) => {
    const [inputValue, setInputValue] = useState(initialValue);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    // Reset inputValue when modal is opened or closed
    useEffect(() => {
        if (isOpen) {
            setInputValue(initialValue || '');
            if (textAreaRef.current) {
                autoResize(textAreaRef.current);
            }
        }
    }, [isOpen, initialValue]);

    // Function to auto-resize the textarea based on content
    const autoResize = (textarea: HTMLTextAreaElement) => {
        textarea.style.height = 'auto'; // Reset height to calculate
        textarea.style.height = `${textarea.scrollHeight}px`; // Set new height based on content
    };

    // Handle text change and auto-resize
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
        autoResize(e.target);
    };

    const handleSave = () => {
        onSave(inputValue);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <Close className="modal-close" onClick={onClose} />
                <div className="modal-title">{title}</div>
                <textarea
                    className="modal-textarea"
                    ref={textAreaRef}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleChange}
                    rows={1} // Initial rows
                    style={{ resize: 'none' }} // Disable manual resizing by the user
                />
                <button className="modal-save" onClick={handleSave}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default Modal;
