import React from 'react';
import './LoadingCircle.css';

const LoadingCircle: React.FC = () => {
    return (
        <div className="loading-container">
            <div className="loading-circle"></div>
        </div>
    );
};

export default LoadingCircle;
