import React from 'react';

const homepage = process.env.REACT_APP_HOMEPAGE_URL || 'http://localhost:3000';

const NotFound: React.FC = () => {
    return (
        <div>
            <title>404 - sr.wlvf.dev</title>
            <div style={{ textAlign: 'center', marginTop: '25rem', color: 'white' }}>
                <h1>404 - Page Not Found</h1>
                <p>Sorry, the page you're looking for doesn't exist.</p>
                <br />
                <a style={{ textDecoration: 'none', color: '#A4DDEB' }} href={homepage}>
                    Go back to the homepage
                </a>
            </div>
        </div>
    );
};

export default NotFound;
