import React, { FC, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { NotificationProps, NotificationItem } from '../types/common';
import './NotificationManager.css';

const Notification: FC<NotificationProps> = ({ id, message, type = 'default', onClose, duration = 3000 }) => {
    useEffect(() => {
        const timer = setTimeout(() => onClose(id), duration);
        return () => clearTimeout(timer);
    }, [duration, id, onClose]);

    return (
        <motion.div
            className={`notification notification-${type}`} // Apply different class based on type
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '100%', opacity: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        >
            <p>{message}</p>
            <button className="notification-close" onClick={() => onClose(id)}>
                &times;
            </button>
        </motion.div>
    );
};

let notificationId = 0;

const NotificationManager: React.FC = () => {
    const [notifications, setNotifications] = useState<NotificationItem[]>([]);

    const addNotification = (message: string, type: 'success' | 'info' | 'default' | 'error' = 'default') => {
        const id = `${notificationId++}`;
        setNotifications((prev) => [...prev, { id, message, type }]);
    };

    const removeNotification = (id: string) => {
        setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    };

    useEffect(() => {
        (window as any).notify = addNotification; // Expose the notify function globally
    }, []);

    return ReactDOM.createPortal(
        <div className="notification-container">
            <AnimatePresence>
                {notifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        id={notification.id}
                        message={notification.message}
                        type={notification.type} // Pass the notification type
                        onClose={removeNotification}
                    />
                ))}
            </AnimatePresence>
        </div>,
        document.body
    );
};

export default NotificationManager;
