import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../utils/axiosInstance';
import { AxiosError } from 'axios';
import './Login.css';
import NotificationManager from '../components/NotificationManager';

const Auth: React.FC = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { login, user } = useAuth();

    useEffect(() => {
        if (user.loggedIn) navigate('/');
    }, [user, navigate]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setError(null);

        if (!isLogin) {
            setError('Registration is currently disabled.');
            return;
        }

        if (!isLogin && password !== confirmPassword) {
            setError(`Passwords do not match. Please try again.`);
            return;
        }

        const endpoint = isLogin ? '/api/login' : '/api/register';
        try {
            const loginData = { email, password };
            const registerData = { email, password, username };

            const response = await axiosInstance.post(endpoint, isLogin ? loginData : registerData);

            login(response.data);
            navigate('/');
        } catch (error: any) {
            console.error(isLogin ? 'Login failed' : 'Registration failed', error);
            if (isLogin && (error as AxiosError)?.response?.status === 401) {
                setError('Login failed. Please check your credentials and try again.');
                return;
            } else if (!isLogin && (error as AxiosError)?.response?.status === 409) {
                setError('Registration failed. Email already used.');
                return;
            } else {
                setError('An error occurred. Please try again.');
                return;
            }
        }
    };

    return (
        <div className="auth-container">
            <title>Login - sr.wlvf.dev</title>
            <NotificationManager />


            <div className="auth-goback">
                <div
                    onClick={() => navigate('/')}
                    style={{
                        position: 'absolute',
                        top: '1em',
                        left: '1em',
                        fontSize: '1.2em',
                        color: 'white',
                        cursor: 'pointer',
                    }}
                >
                    {`← Home`}
                </div>
            </div>

            <div className="auth-card">
                <h1 className="auth-header">{isLogin ? 'Login' : 'Register'}</h1>
                {error && <div className="auth-error">{error}</div>}
                <form onSubmit={handleSubmit}>
                    {!isLogin && (
                        <div className="auth-input-group">
                            <label className="auth-label">Username</label>
                            <input
                                className="auth-input"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    <div className="auth-input-group">
                        <label className="auth-label">Email</label>
                        <input
                            className="auth-input"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="auth-input-group">
                        <label className="auth-label">Password</label>
                        <input
                            className="auth-input"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {!isLogin && (
                        <div className="auth-input-group">
                            <label className="auth-label">Confirm Password</label>
                            <input
                                className="auth-input"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    <button className="auth-submit-button" type="submit">
                        Submit
                    </button>
                </form>
                <div
                    className="auth-switch-button"
                    onClick={() => {
                        setError(null);
                        setEmail('');
                        setUsername('');
                        setPassword('');
                        setConfirmPassword('');
                        setIsLogin(!isLogin);
                    }}
                >
                    {isLogin ? 'Switch to Register' : 'Switch to Login'}
                </div>
            </div>
        </div>
    );
};

export default Auth;
