import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NotificationManager from '../components/NotificationManager';
import LoadingCircle from '../components/LoadingCircle';
import NavBar from '../components/NavBar';
import Modal from '../components/Modal';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../utils/axiosInstance';
import { Create } from '@mui/icons-material';
import { User } from '../types/common';
import './UserProfile.css';
import '../index.css';

const UserProfile: React.FC = () => {
    const { username, selection } = useParams<{ username: string; selection: string }>();

    const [pageUser, setPageUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { user } = useAuth();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [profileText, setProfileText] = useState(pageUser?.bio || '');

    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);

    const fetchPageUser = async () => {
        try {
            const response = await axiosInstance.post(`/api/users`, { username });

            if (user && response.data.username && user.username === response.data.username) {
                response.data.isMe = true;
            }

            const userProfile = response.data;

            setPageUser(userProfile);

            if (selection === 'about' && userProfile?.bio !== profileText)
                setProfileText(
                    userProfile?.bio ? userProfile.bio : `We don't know much about ${userProfile.username} yet.`
                );
        } catch (err: any) {
            if (err?.response?.status === 404) {
                navigate('/404'); // Redirect to a "404 Not Found" page
            } else {
                console.error(err);
                setError(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const triggerNotification = (message: string, message_type: string) => {
        (window as any).notify(message, message_type);
    };

    const handleSave = async (newValue: string) => {
        // Save the new bio to the database
        const response = await axiosInstance.put(`/api/users/update`, {
            username: pageUser?.username,
            data: {
                bio: newValue,
            },
        });

        if (response.status !== 200) {
            triggerNotification('Failed to save changes', 'error');
            return;
        }

        fetchPageUser();

        triggerNotification('Changes saved successfully!', 'success');
    };

    const clientIsUser = pageUser?.isMe;

    useEffect(() => {
        if (!selection || !['about', 'favorites', 'recently-played', 'statistics'].includes(selection)) {
            navigate(`/user/${username}/about`);
            return;
        }

        fetchPageUser();
    }, [username, navigate, selection, user, profileText]);

    const connectService = async (service: string) => {
        // This route will redirect the user to the OAuth provider
        window.location.href = `${process.env.REACT_APP_API_URL}/auth/${service}`;
    };

    if (isLoading || !pageUser) {
        return <LoadingCircle />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <NavBar transparent={true} />
            <NotificationManager />

            <div className="user-banner">
                <img src={pageUser.bannerUrl} alt="User Banner" />
            </div>
            <div className="user-profile">
                <img className="user-avatar" src={pageUser.avatarUrl} alt="User Avatar" />
                <div className="user-name">{pageUser.username}</div>
            </div>
            <div className="user-navbar">
                <div
                    className="user-navbar-item"
                    style={{ color: selection === 'about' ? 'var(--accent-color)' : 'white' }}
                    onClick={() => navigate(`/user/${username}/about`)}
                >
                    About
                </div>
                <div
                    className="user-navbar-item"
                    style={{ color: selection === 'favorites' ? 'var(--accent-color)' : 'white' }}
                    onClick={() => navigate(`/user/${username}/favorites`)}
                >
                    Favorites
                </div>
                <div
                    className="user-navbar-item"
                    style={{ color: selection === 'recently-played' ? 'var(--accent-color)' : 'white' }}
                    onClick={() => navigate(`/user/${username}/recently-played`)}
                >
                    Recently Played
                </div>
                <div
                    className="user-navbar-item"
                    style={{ color: selection === 'statistics' ? 'var(--accent-color)' : 'white' }}
                    onClick={() => navigate(`/user/${username}/statistics`)}
                >
                    Statistics
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onSave={handleSave}
                title="Edit Bio"
                placeholder="Enter new bio text"
                initialValue={profileText}
            />
            {selection === 'about' && (
                <div>
                    <div className="user-content">
                        <title>{pageUser.username} - About</title>

                        <div
                            className="user-content-section"
                            style={{
                                flex: 69,
                            }}
                        >
                            <div className="user-content-item">
                                {clientIsUser && (
                                    <Create className="user-content-item-edit-icon" onClick={handleModalOpen} />
                                )}
                                <div className="user-content-item-title">Bio</div>
                                <div className="user-content-item-value">{profileText}</div>
                            </div>
                            <div className="user-content-item">
                                <div className="user-content-item-title">Joined</div>
                                <div className="user-content-item-value">
                                    {new Date(pageUser.joined).toLocaleString()}
                                </div>
                            </div>
                        </div>
                        <div
                            className="user-content-section"
                            style={{
                                flex: 31,
                                textAlign: 'right',
                            }}
                        >
                            <div className="user-content-item">
                                <div className="user-content-item-title">Twitch</div>
                                {/* <div className="user-content-item-value">{pageUser.connections}</div> */}
                                {clientIsUser && (
                                    <div className="connect-button">
                                        <div onClick={() => connectService('twitch')} className="connect-twitch-button">
                                            Connect
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="user-content-item">
                                <div className="user-content-item-title">Spotify</div>
                                {/* <div className="user-content-item-value">{pageUser.connections}</div> */}
                                {clientIsUser && (
                                    <div className="connect-button">
                                        <div
                                            onClick={() => connectService('spotify')}
                                            className="connect-spotify-button"
                                        >
                                            Connect
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="user-content-item">
                                <div className="user-content-item-title">Discord</div>
                                {/* <div className="user-content-item-value">{pageUser.connections}</div> */}
                                {clientIsUser && (
                                    <div className="connect-button">
                                        <div
                                            onClick={() => connectService('discord')}
                                            className="connect-discord-button"
                                        >
                                            Connect
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <div className="user-content-item user-bio">
                            <div className="user-content-item-title">Bio</div>
                            <div className="user-content-item-value">{pageUser.bio}</div>
                        </div> */}

                        {/* <div className="user-content-item">
                        <div className="user-content-item-title">Website</div>
                        {/* <div className="user-content-item-value">{pageUser.website}</div> */}
                        {/* </div> */}
                    </div>
                </div>
            )}
            {selection === 'favorites' && (
                <div className="user-content">
                    <title>{pageUser.username} - Favorites</title>
                    <div className="user-content-item">
                        <div className="user-content-item-title">Top Streamers</div>
                        {/* <div className="user-content-item-value">{pageUser.favorites.streamers}</div> */}
                    </div>
                    <div className="user-content-item">
                        <div className="user-content-item-title">Top Songs</div>
                        {/* <div className="user-content-item-value">{pageUser.favorites.songs}</div> */}
                    </div>
                </div>
            )}
            {selection === 'recently-played' && (
                <div className="user-content">
                    <title>{pageUser.username} - Recently Played</title>
                    <div className="user-content-item">
                        <div className="user-content-item-title">Recently Played</div>
                        {/* <div className="user-content-item-value">{pageUser.recentlyPlayed}</div> */}
                    </div>
                </div>
            )}
            {selection === 'statistics' && (
                <div className="user-content">
                    <title>{pageUser.username} - Statistics</title>
                    <div className="user-content-item">
                        <div className="user-content-item-title">Total Songs Played</div>
                        {/* <div className="user-content-item-value">{pageUser.statistics}</div> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserProfile;
