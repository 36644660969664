import React, { createContext, useState, useEffect, useContext, ReactNode, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { AuthContextType, User } from '../types/common';

const loggedOutUser = {
    loggedIn: false,
    isMe: false,
    username: '',
    avatarUrl: '/avatar-404.png',
    bannerUrl: '/banner-404.png',
    joined: '',
    bio: ''
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User>({
        loggedIn: null,
        isMe: false,
        username: '',
        avatarUrl: '/avatar-404.png',
        bannerUrl: '/banner-404.png',
        joined: '',
        bio: ''
    });

    const login = (userData: any) => {
        // Set up user data
        const user = {
            ...userData,
            loggedIn: true,
        };

        // Set user data in state
        setUser(user);

        // Store user data in local storage
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('lastLogin', Date.now().toString());
    };

    const logout = () => {
        // Remove user data from state
        setUser(loggedOutUser);

        // Remove user data from local storage
        localStorage.removeItem('user');
        localStorage.removeItem('lastLogin');

        // Clear the cookie on logout
        axiosInstance.post('/api/logout');
    };

    const refreshUser = useCallback(async () => {
        try {
            // Get user data from the server
            const response = await axiosInstance.get('/api/me');
            if (!response.data) {
                setUser(loggedOutUser);
                return;
            }

            if (response.data.error) {
                setUser(loggedOutUser);
                return;
            }

            // Set up user data
            const user = {
                ...response.data,
                loggedIn: true,
            };

            // Set user data in state
            setUser(user);

            // Store user data in local storage
            localStorage.setItem('lastLogin', Date.now().toString());
        } catch (error) {
            // Remove user data from state
            setUser(loggedOutUser);
        }
    }, []);

    useEffect(() => {
        // Check if user data is stored in local storage
        const storedUserLastLogin = localStorage.getItem('lastLogin');
        if (storedUserLastLogin) {
            // Check if user data is older than 2 weeks
            const timeSinceLastLogin = Date.now() - parseInt(storedUserLastLogin);
            const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000; // 2 weeks in milliseconds

            // Logout if user data is older than 2 weeks
            if (timeSinceLastLogin > twoWeeksInMilliseconds) {
                logout();
                return;
            }
        }
        refreshUser();
    }, [refreshUser]);

    return <AuthContext.Provider value={{ user, login, logout, refreshUser }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
